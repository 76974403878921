<!--
// nuxt-ui/components/Shared/slider-matches/SliderMatchesTurnMatch.vue
-->
<script setup lang="ts">
import type { Team } from "~/src/team/domain/models/Team";
import type { Match } from "~/src/match/domain/models/Match";
import type { SocialMedia } from "~/src/region/domain/models/Region";
import { setAliasUrl, slugify } from "~/src/Shared/utils";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { useMatches } from "~/nuxt-ui/Shared/composables/season/matches";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import matchUiService from "~/src/match/infrastructure/ui-services/matchUiService";
import matchService from "~/src/match/domain/services/matchService";
import nuxtLink from "#app/components/nuxt-link";

type Props = {
  match: Match;
  teams: Record<number, Team>;
  showGroup: boolean;
  regionReference?: number;
};

const props = defineProps<Props>();
const route = useRoute();
const { locale } = useI18n();
const localePath = useLocalePath();
const { region } = useCompetitionStore();
const { webContext } = useWebContext();
const { regionParam, regionTimeZone, isWorldCup, isRegionHome, findRegionInfoById } = useRegions();
const { formatMatchTime } = useMatches();
const { matchHasPage, matchIsLive, trackLiveMatch } = matchService();
const { transformStatus } = matchUiService();
const intervalId = ref<NodeJS.Timeout>();

const matchData = ref<Match | undefined>(props.match);

const matchMinute = ref<Match["currentMinute"] | undefined>(props.match.currentMinute);

const setLive = computed(() => {
  return matchIsLive(props.match);
});

const regionDateUsed = props.regionReference
  ? findRegionInfoById(props.regionReference, "slug")
  : regionParam
    ? regionParam
    : "espana";

const regionTimeUsed = props.regionReference
  ? findRegionInfoById(props.regionReference, "timeZone")
  : regionTimeZone;

const matchTime = formatMatchTime(locale.value, props.match.date, regionDateUsed, regionTimeUsed);
const matchDate = new Date(props.match.date).toLocaleDateString(locale.value, {
  day: "numeric",
  month: "2-digit",
  timeZone: regionTimeUsed,
});

const showPenalties = computed(() => {
  if (!props.match || !props.match.status) return false;
  else if (props.match.status === "inPlayP" || transformStatus(props.match.status) === "ended") {
    return true;
  }
});

const socialMedia: SocialMedia[] = region?.social[webContext] || [];
const liveButtonUrl: string | undefined =
  isWorldCup && locale.value === "es"
    ? "https://www.twitch.tv/kingsleague"
    : socialMedia.find(s => s.name === "twitch")?.url;

const clearMatchInterval = () => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
    intervalId.value = undefined;
  }
};

const showDateTime = time => {
  return time === "0:00h ESP" || time === "12:00 AM ESP" || time === "0:00h CDMX" || time === "12:00 AM CDMX";
};

// onMounted(() => {
//   if (props.match && props.match.status && trackLiveMatch(props.match)) {
//     intervalId.value = setInterval(async function () {
//       if (matchData.value) {
//         matchMinute.value = matchData.value?.currentMinute;
//         if (matchData.value && !matchIsLive(props.match)) {
//           clearMatchInterval();
//         }
//       }
//     }, $MATCH_REFRESH_INTERVAL_SLIDE);
//   }
// });

onUnmounted(() => {
  clearMatchInterval();
});
</script>

<template>
  <div
    class="card-match"
    :class="[{ 'bg-gray-900 live-match': setLive }]"
    :data-last-played="match.isLastPlayed"
    :id="match.id"
  >
    <div class="match-info-wrapper">
      <div v-if="setLive" class="flex justify-between items-center gap-x-2">
        <span class="minute-live-match" v-if="matchMinute || matchMinute === 0">{{ matchMinute }}'</span>
        <a :href="liveButtonUrl" target="_blank" class="is-live-box">
          <span class="bg-red-600 w-2 h-2 rounded-full animate-pulse"></span>
          {{ $t("slider.matches.seeLive") }}
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.8 4H8M8 4V8.2M8 4L3 9"
              stroke="#F98080"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>

      <div v-else-if="matchDate && matchTime && !showDateTime(matchTime)" class="match-hour">
        {{ matchDate }} -
        {{ matchTime }}
      </div>
      <div v-else class="match-hour">-</div>
      <div v-if="match.groupName && showGroup" class="match-group">
        {{ match.groupName }}
      </div>
    </div>
    <component
      :is="matchHasPage(match.id) ? nuxtLink : 'div'"
      :to="
        matchHasPage(match.id)
          ? localePath({
              name: 'region-matches-matchId-homeTeam-vs-awayTeam',
              params: {
                region: regionParam,
                matchId: match.id,
                homeTeam: slugify(teams[match.participants.homeTeamId].shortName || ''),
                awayTeam: slugify(teams[match.participants.awayTeamId].shortName || ''),
              },
            })
          : undefined
      "
      class="match-data-wrapper"
      :no-prefetch="nuxtLink && !setLive && isRegionHome ? true : undefined"
    >
      <div class="team-data-wrapper">
        <div class="team-short-name">
          {{ teams[match.participants.homeTeamId].shortName }}
        </div>
        <div class="team-logo-wrapper">
          <NuxtImg
            :src="setAliasUrl(teams[match.participants.homeTeamId].logo.url)"
            :alt="teams[match.participants.homeTeamId].completeName || ''"
            class="max-w-10 h-10"
            height="40"
            width="40"
            loading="lazy"
          />
        </div>
      </div>
      <div class="home-team-result-wrapper">
        <div class="flex justify-center items-center h-full">
          <div>
            {{ matchData?.scores.homeScore === null ? "-" : matchData?.scores.homeScore }}
            <span class="text-primary text-xs" v-if="showPenalties">{{ matchData?.scores.homeScoreP }}</span>
          </div>
        </div>
      </div>
      <div class="team-data-wrapper">
        <div class="team-short-name">
          {{ teams[match.participants.awayTeamId].shortName }}
        </div>
        <div class="team-logo-wrapper">
          <NuxtImg
            :src="setAliasUrl(teams[match.participants.awayTeamId].logo.url)"
            :alt="teams[match.participants.awayTeamId].completeName || ''"
            :loading="setLive ? 'eager' : 'lazy'"
            class="max-w-10 h-10"
            height="40"
            width="40"
          />
        </div>
      </div>
      <div class="away-team-result-wrapper">
        <div class="flex justify-center items-center h-full">
          <div>
            {{ matchData?.scores.awayScore === null ? "-" : matchData?.scores.awayScore }}
            <span class="text-primary text-xs" v-if="showPenalties">{{ matchData?.scores.awayScoreP }}</span>
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<style scoped>
.card-match {
  @apply rounded-md min-w-[168px] p-2.5 max-w-full hover:bg-gray-900;
}

.match-info-wrapper {
  @apply flex flex-nowrap justify-between items-center pb-2 border-b border-b-gray-600;
}

.is-live-box {
  @apply flex gap-1 items-center text-xs text-red-400 uppercase bg-gray-800 rounded justify-center px-1;
}

.minute-live-match {
  @apply text-gray-500 text-xs font-medium tracking-tight;
}

.match-hour {
  @apply text-xs font-medium text-gray-200;
}

.match-group {
  @apply px-1.5 rounded border border-primary uppercase text-[10px] font-normal text-primary leading-[17px];
}

.match-data-wrapper {
  @apply grid grid-cols-[1fr_1fr_40px] gap-x-1.5 mt-4 text-center;
}

.team-data-wrapper {
  @apply grid grid-cols-[38px_1fr] items-center col-span-2;
}

.team-short-name {
  @apply text-white font-bold text-left;
}

.team-logo-wrapper {
  @apply flex flex-col items-start justify-center py-1 px-1.5;
}

.home-team-result-wrapper {
  @apply bg-gray-800 font-bold text-center rounded-t-md;
}

.away-team-result-wrapper {
  @apply bg-gray-800 font-bold text-center rounded-b-md;
}
</style>
